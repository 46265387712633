<template lang="pug">
.brand-wrapper.brand-display-rules
  .container-fluid(v-if="loaded")
    .font-size-0--75.text-color-mild-gray {{ $t('useCase') }}
    .d-flex.align-items-center.mb-5
      h1 {{ name[$i18n.locale] }}
    .predefined-segments.row.mb-5.align-items-end
      .col-3
        om-select#shopify-segment(
          label="Shopify segment"
          :placeholder="$t('select')"
          :options="shopifySegmentOptions"
          v-model="shopifySegment"
        )
      .col-3
        om-select#non-shopify-segment(
          label="Non-Shopify segment"
          :placeholder="$t('select')"
          :options="nonShopifySegmentOptions"
          v-model="nonShopifySegment"
        )
      .col-3
        om-button(primary @click="updateSegments") {{ $t('save') }}
    campaign-settings-box(
      :show="activeBox === 'events'"
      @activate="activeBox = 'events'"
      @close="activeBox = 'frontendRules'"
    )
      template(#title) {{ $t('whenToShowLong') }}
      template(#button) {{ $t('next') }}
      template(#content)
        .pt-3
          transition-group.overflow-hidden(name="fade" tag="div")
            template(v-for="(event, index) in events")
              .campaign-setting-card.card.cursor-pointer(
                @click="showEventsModal(event.type)"
                :key="event.type"
              )
                .card-body.card-body-line.px-0
                  event-box(:event="event")
                    template(slot="action")
                      .d-flex.justify-content-end.flex-grow-1
                        .remove-setting-box(@click.stop="removeRule('events', index)")
                          i.fas.fa-times.remove-setting
                .rule-logical-condition.rule-logical-condition-or(
                  v-if="index < events.length - 1"
                  :class="`rule-logical-condition-or-${$i18n.locale}`"
                ) {{ $t('or') }}
            campaign-settings-skeleton(
              type="event"
              key="skeleton"
              @click="$modal.show('new-events-modal')"
            )
    campaign-settings-box(
      :show="activeBox === 'frontendRules'"
      @activate="activeBox = 'frontendrules'"
      @close="updateSettings"
    )
      template(#title) {{ $t('whoToShowLong') }}
      template(#button) {{ $t('save') }}
      template(#content)
        .pt-3
          transition-group.overflow-hidden(name="fade" tag="div")
            template(v-for="(frontendRule, index) in frontendRules")
              .campaign-setting-card.card.cursor-pointer(
                @click="addNewRule(frontendRule.type)"
                :key="frontendRule.type"
              )
                .card-body.card-body-line.px-0
                  frontend-rule-box(
                    :frontendRule="frontendRule"
                    :campaign="{ domain: 'optimonk.com' }"
                  )
                    template(#action)
                      .d-flex.justify-content-end.flex-grow-1
                        .remove-setting-box(@click.stop="removeRule('frontendRules', index)")
                          i.fas.fa-times.remove-setting
                .rule-logical-condition.rule-logical-condition-and(
                  v-if="index < frontendRules.length - 1"
                  :class="`rule-logical-condition-and-${$i18n.locale}`"
                ) {{ $t('and') }}
            campaign-settings-skeleton(type="frontendRule" key="skeleton" @click="addNewRule")
    //- .mt-5
      button.brand-btn.brand-btn-primary.mx-auto(@click="updateSettings") {{ $t('save') }}
  new-campaign-setting-rule(
    settingType="events"
    :settingArray.sync="events"
    @editRule="editRule('events', $event)"
  )
  new-campaign-setting-rule(
    settingType="frontendRules"
    :settingArray.sync="frontendRules"
    @editRule="editRule('frontendRules', $event)"
    domain="optimonk.com"
    :campaign="{ templateType: 'popup' }"
  )
</template>

<script>
  import Vue from 'vue';
  import CampaignSettingsBox from '@/components/CampaignSettings/SettingsBox';
  import NewCampaignSettingRule from '@/components/Modals/NewCampaignSettingRule.vue';
  import CampaignSettingsSkeleton from '@/components/CampaignSettings/Skeleton.vue';
  import EventBox from '@/components/CampaignSettings/EventBox.vue';
  import FrontendRuleBox from '@/components/CampaignSettings/FrontendRuleBox.vue';

  import GET_USE_CASE from '@/graphql/GetUseCase.gql';
  import UPSERT_USE_CASE from '@/graphql/UseCaseUpsert.gql';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import SEGMENTS from '@/graphql/Segments.gql';

  export default {
    components: {
      CampaignSettingsBox,
      NewCampaignSettingRule,
      CampaignSettingsSkeleton,
      EventBox,
      FrontendRuleBox,
    },
    data: () => ({
      loaded: false,
      name: null,
      frontendRules: [],
      events: [],
      activeBox: 'events',
      segments: [],
      shopifySegment: null,
      nonShopifySegment: null,
    }),
    computed: {
      id() {
        return this.$route.params.id;
      },
      shopifySegmentOptions() {
        return this.segments
          .filter(({ showFor }) => showFor === 'shopify')
          .map(({ _id, name }) => {
            return { key: _id, value: name[this.$i18n.locale] };
          });
      },
      nonShopifySegmentOptions() {
        return this.segments
          .filter(({ showFor }) => showFor !== 'shopify')
          .map(({ _id, name }) => {
            return { key: _id, value: name[this.$i18n.locale] };
          });
      },
    },
    apollo: {
      getUseCase: {
        query: GET_USE_CASE,
        variables() {
          return { id: this.id };
        },
        manual: true,
        result({ data, loading }) {
          if (!loading) {
            this.events = data.useCase.events;
            this.frontendRules = data.useCase.frontendRules;
            this.shopifySegment = data.useCase.shopifySegment;
            this.nonShopifySegment = data.useCase.nonShopifySegment;
            this.name = data.useCase.name;
            this.loaded = true;
          }
        },
      },
      segments: {
        query: SEGMENTS,
        variables: { type: 'predefined' },
      },
    },
    watch: {
      loaded(n) {
        if (n) this.$store.commit('showAdminLoader', false);
      },
      segments(n) {
        if (!n) return;
        if (this.shopifySegment && typeof this.shopifySegment !== 'object') {
          this.shopifySegment = this.shopifySegmentOptions.find(
            ({ key }) => key === this.shopifySegment,
          );
        }
        if (this.nonShopifySegment && typeof this.nonShopifySegment !== 'object') {
          this.nonShopifySegment = this.nonShopifySegmentOptions.find(
            ({ key }) => key === this.nonShopifySegment,
          );
        }
      },
      shopifySegment(n) {
        if (n && typeof n !== 'object') {
          this.shopifySegment = this.shopifySegmentOptions.find(
            ({ key }) => key === this.shopifySegment,
          );
        }
      },
      nonShopifySegment(n) {
        if (n && typeof n !== 'object') {
          this.nonShopifySegment = this.nonShopifySegmentOptions.find(
            ({ key }) => key === this.nonShopifySegment,
          );
        }
      },
    },
    created() {
      this.$store.commit('showAdminLoader', true);
    },
    methods: {
      showEventsModal(type) {
        this.$modal.show('new-events-modal', { type });
      },
      editRule(type, rule) {
        for (let i = 0; i < this[type].length; i++) {
          if (this[type][i].type === rule.type) {
            Vue.set(this[type], i, rule);
            break;
          }
        }
      },
      removeRule(type, index) {
        this.$modal.show('dialog', {
          text: this.$t('confirmationDialog'),
          buttons: [
            {
              title: this.$t('yes'),
              class: getBrandedClassString({ primary: true }, 'mr-3'),
              handler: () => {
                this[type].splice(index, 1);
                this.$modal.hide('dialog');
              },
            },
            {
              title: this.$t('cancel'),
              default: true,
              class: getBrandedClassString({ secondary: true }),
            },
          ],
        });
      },
      addNewRule(type) {
        this.$modal.show('new-frontendrules-modal', { ruleset: 'default', type });
      },
      async updateSegments() {
        const result = await this.$apollo.mutate({
          mutation: UPSERT_USE_CASE,
          variables: {
            id: this.id,
            settings: {
              shopifySegment: this.shopifySegment ? this.shopifySegment.key : null,
              nonShopifySegment: this.nonShopifySegment ? this.nonShopifySegment.key : null,
            },
          },
        });

        if (result) {
          this.$notify({ type: 'success', text: this.$t('notifications.saveSuccess') });
        } else {
          this.$notify({ type: 'error', text: this.$t('notifications.saveError') });
        }
      },
      async updateSettings() {
        const result = await this.$apollo.mutate({
          mutation: UPSERT_USE_CASE,
          variables: {
            id: this.id,
            settings: {
              events: this.events,
              frontendRules: this.frontendRules,
              shopifySegment: this.shopifySegment ? this.shopifySegment.key : null,
              nonShopifySegment: this.nonShopifySegment ? this.nonShopifySegment.key : null,
            },
          },
        });

        if (result) {
          this.$router.replace({ name: 'template_manager' });
        } else {
          console.log('@@@udpate failed');
        }
      },
    },
  };
</script>
